
import { computed, defineComponent, reactive, ref } from "vue";
import authInterface from "@/authentication/AuthenticationInterface";
import ButtonAscendant from "@/views/components/forms/ButtonAscendant.vue";
import TextInputField from "@/views/components/forms/TextInputField.vue";
import { useRouter } from "vue-router";
import VueQrcode from "@chenfengyuan/vue-qrcode";

export default defineComponent({
  components: {
    ButtonAscendant,
    TextInputField,
    VueQrcode,
  },
  setup() {
    const router = useRouter();

    const username = ref("");
    const password = ref("");
    const errorMessage = ref("");
    const loggingIn = ref(true);
    const settingPassword = ref(false);
    const settingMfa = ref(false);
    const doingMfa = ref(false);
    const resultingUser = reactive({} as any);
    const mfaSetupCode = ref("");
    const totpConfirmation = ref("");
    const mfaCode = ref("");

    const totpSetupUrl = computed(() => `otpauth://totp/AscendantHQ:${username.value}?secret=${mfaSetupCode.value}&issuer=AscendantHQ`)
    const newPassword = ref("");
    const passwordConfirm = ref("");

    const passwordValidationMessage = computed(() => {
      if (newPassword.value.length <= 25)
        return "Your password must be at least 25 characters long and include numbers, letters of both cases and at least one symbol.";
      if (newPassword.value !== passwordConfirm.value)
        return "Your passwords do not match.";
      return "";
    });

    const isValid = computed(
      () => passwordValidationMessage.value.length === 0
    );

    const tryLogin = async () => {
      const result = await authInterface.login(username.value, password.value);

      if (result === null) {
        // Success!
        router.replace("/");
      } else if (result instanceof String || typeof result == "string") {
        errorMessage.value = result as string;
      } else {
        loggingIn.value = false;
        resultingUser.value = result;

        if ((result as any).challengeName === "MFA_SETUP") {
          mfaSetupCode.value = await authInterface.startTotp(
            resultingUser.value
          );
          settingMfa.value = true;
        } else if ((result as any).challengeName === "SOFTWARE_TOKEN_MFA") {
          doingMfa.value = true;
        } else {
          settingPassword.value = true;
        }
      }
    };

    const trySetPassword = async () => {
      try {
        await authInterface.finishRegistration(
          resultingUser.value,
          newPassword.value
        );
        settingPassword.value = false;
        await tryLogin();
      } catch (e) {
        errorMessage.value = e.toString();
      }
    };

    const trySetMfa = async () => {
      await authInterface.finishTotp(
        resultingUser.value,
        totpConfirmation.value
      );
    };

    const confirmMfa = async () => {
      await authInterface.confirmMfa(resultingUser.value, mfaCode.value);
    };

    const logout = async () => {
      await authInterface.logout();
    };

    return {
      username,
      password,
      newPassword,
      passwordConfirm,
      trySetPassword,
      errorMessage,
      tryLogin,
      loggingIn,
      isValid,
      logout,
      resultingUser,
      trySetMfa,
      settingMfa,
      mfaSetupCode,
      totpConfirmation,
      doingMfa,
      mfaCode,
      confirmMfa,
      settingPassword,
      passwordValidationMessage,
      totpSetupUrl
    };
  },
});

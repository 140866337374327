<template>
  <div class='input-block'>
    <div class="input-title">{{title}}</div>
    <input :type="inputType" :value="value" @input="$emit('update:value', $event.target.value)" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";


export default defineComponent({
  props: {
    title: String,
    value: String,
    inputType: {
      type: String as PropType<'text' | 'number' | 'password'>,
      default: 'text'
    }
  },
})

</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";

.input-block {
  border: 2px solid $ascendant-light;
  width: 320px;
  display: block;
  margin: 8px auto 8px auto;
}

.input-title {
  color: $ascendant-mediumDark;
  padding-left: 16px;
  text-emphasis: italic;
  font-size: 14px;
}

input {
  border: none;
  width: 284px;
  margin: 0 16px 8px 16px;
  color: $ascendant-mediumDark;
  border-bottom: 2px solid white;
}

input:focus {
  outline: none;
  border-bottom: 2px solid $ascendant-primary;
}

</style>
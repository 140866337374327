
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    onClick: {
      type: Function as PropType<() => void>,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    backgroundColor: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    fontSize: {
      type: String,
      required: true,
    },
  },
});

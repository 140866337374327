import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import authInterface from "../authentication/AuthenticationInterface";
import Login from "@/views/unauthenticated/Login.vue";
import AuthenticatedLayout from "@/views/AuthenticatedLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "AuthenticatedRoot",
    component: AuthenticatedLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      {
        path: "/weekly-report",
        name: "WeeklyReport",
        component: () =>
          import(
            /* webpackChunkName: "WeeklyReport" */ "../views/WeeklyReport.vue"
          ),
      },
      {
        path: "/practices",
        name: "Practices",
        component: () =>
          import(/* webpackChunkName: "Practices" */ "../views/Practices.vue"),
      },
      {
        path: "/practices/:id",
        name: "PracticeDetails",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "PracticeDetails" */ "../views/PracticeDetails.vue"
          ),
      },
      {
        path: "/practices/create",
        name: "CreatePractice",
        component: () =>
          import(
            /* webpackChunkName: "CreateProvider" */ "../views/CreatePractice.vue"
          ),
      },
      {
        path: "/providers",
        name: "Providers",
        component: () =>
          import(/* webpackChunkName: "Providers" */ "../views/Providers.vue"),
      },
      {
        path: "/providers/create/:id",
        props: true,
        name: "CreateProvider",
        component: () =>
          import(
            /* webpackChunkName: "CreateProvider" */ "../views/CreateProvider.vue"
          ),
      },
      {
        path: "/announcements",
        name: "ListAnnouncements",
        component: () =>
          import(
            /* webpackChunkName: "ListAnnouncements" */ "../views/Announcements/ListAnnouncements.vue"
          ),
      },
      {
        path: "/announcements/:id",
        props: true,
        name: "ViewAnnouncement",
        component: () =>
          import(
            /* webpackChunkName: "ListAnnouncements" */ "../views/Announcements/ViewAnnouncement.vue"
          ),
      },
      {
        path: "/announcements/create",
        name: "CreateAnnouncement",
        component: () =>
          import(
            /* webpackChunkName: "CreateAnnouncement" */ "../views/Announcements/CreateAnnouncement.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    authInterface.isLoggedIn().then((res) => {
      if (res) {
        next();
      } else {
        next({ name: "Login" });
      }
    });
  } else {
    next();
  }
});

export default router;

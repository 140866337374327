<template>
  <button
    :disabled="disabled"
    @click="onClick"
    class="Button"
    :style="{ backgroundColor, color, fontSize }"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    onClick: {
      type: Function as PropType<() => void>,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    backgroundColor: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    fontSize: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

button {
  border: none;
  font-size: 24px;
  font-family: inherit;
  padding: 8px 24px;
  color: white;
  background-color: $ascendant-primary;
  border-radius: 40px;
}

button:hover:enabled {
  text-decoration: underline;
  cursor: pointer;
}

button:disabled {
  background-color: $ascendant-primaryDisabled;
}

button:focus {
  outline: none;
}
</style>

<template>
  <div id="app">
    <Header />
    <div class="main-flex-container container" >
      <div class="content-view-container">
        <router-view class="content-view" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import Header from "@/views/components/layout/Header.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "AuthenticatedLayout",
  components: {
    Header,
  },
  setup() {
    const store = useStore();

    const fetchThings = async () => {
      store.dispatch("getPractices");
      store.dispatch("getProviders");
      store.dispatch("getAnnouncements");
    };

    onMounted(fetchThings);
  },
  methods: {

  },
});
</script>

<style lang="scss" scoped>
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-flex-container {
  display: flex;
  height: 100%;
  flex: 1;
  padding-top: 1rem;
}

.content-view-container {
  flex: 1;
  // margin: 1rem;
}

.content-view {
  height: 100%;
}
</style>

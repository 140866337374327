import { WeeklyReport } from "./dto/WeeklyReport";
import authenticationInterface from "@/authentication/AuthenticationInterface";
import { Practice } from "@/store/Practice";
import { Provider } from "@/store/Provider";
import { Announcement } from "@/store/Announcement";

const baseUrl =
  "https://2vyyljto2k.execute-api.us-west-2.amazonaws.com/internal-prod";
const weeklyReportUrl = `${baseUrl}/weekly-report`;
const practicesUrl = `${baseUrl}/practices`;
const providersUrl = `${baseUrl}/providers`;
const createProviderUrl = `${baseUrl}/providers/create`;
const createPracticeUrl = `${baseUrl}/practices/create`;
const announcementsUrl = `${baseUrl}/announcements`;
const createAnnouncementUrl = `${baseUrl}/announcements/create`;

import { extractProviderRow } from "@/views/CreateProviderHelpers";

class ApiInterface {
  async getWeeklyReport(rundate: Date): Promise<WeeklyReport> {
    const request = await this.makeGetRequest<WeeklyReport>(
      `${weeklyReportUrl}?rundate=${rundate.toString()}`
    );
    return request;
  }

  async getPractices() {
    const response = await this.makeGetRequest<Practice[]>(practicesUrl);
    return response;
  }

  async getProviders() {
    const response = await this.makeGetRequest<Provider[]>(providersUrl);
    response.forEach((element) => {
      if (element.createdDate) {
        element.createdDate = new Date(
          Date.parse(element.createdDate.toString())
        );
        element.createdDateFormat = element.createdDate?.shortFormat();
      }
      if (element.lastModifiedDate) {
        element.lastModifiedDate = new Date(
          Date.parse(element.lastModifiedDate.toString())
        );
        element.lastModifiedDateFormat = element.lastModifiedDate?.shortFormat();
      }
      
    });
    return response;
  }

  async createProviders(
    practiceId: string,
    providerInfos: ReturnType<typeof extractProviderRow>[]
  ): Promise<boolean> {
    try {
      const payload = {
        practiceId,
        providers: providerInfos,
      };

      const response = await this.makePostRequest(createProviderUrl, payload);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async createPractice(
    name: string,
    address: string,
    city: string,
    state: string,
    zipCode: string,
    phone: string
  ): Promise<string> {
    const payload = {
      name,
      address,
      city,
      state,
      zipCode,
      phone,
    };

    const response = await this.makePostRequest<{ practice: { id: string } }>(
      createPracticeUrl,
      payload
    );
    return response.practice.id;
  }

  async getAnnouncements(): Promise<Announcement[]> {
    const response = await this.makeGetRequest<Announcement[]>(
      announcementsUrl
    );
    return response;
  }

  async getAnnouncement(announcementId: string): Promise<Announcement> {
    const response = await this.makeGetRequest<Announcement>(
      `${announcementsUrl}/${announcementId}`
    );
    return response;
  }

  async createAnnouncement(
    title: string,
    subtitle: string,
    body: string,
    postedDate: Date
  ): Promise<string> {
    const payload = {
      title,
      subtitle,
      body,
      postedDate,
    };

    const response = await this.makePostRequest<string>(
      createAnnouncementUrl,
      payload
    );
    return response;
  }

  private async makeGetRequest<T>(url: string): Promise<T> {
    const request = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${await authenticationInterface.sessionToken()}`,
      },
    });

    if (!request.ok) {
      console.error(request);
      throw "Request was not ok";
    }

    const result = (await request.json()) as T;

    return result;
  }

  private async makePostRequest<T>(url: string, payload: any): Promise<T> {
    const stringified = JSON.stringify(payload);

    const request = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${await authenticationInterface.sessionToken()}`,
      },
      body: stringified,
    });

    if (!request.ok) {
      console.error(request);
      throw "Request was not ok";
    }

    const result = (await request.json()) as T;
    return result;
  }
}

export default new ApiInterface();

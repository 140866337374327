<template>
  <div>
    <header class="header">
      <div class="brand-container">
        <img src="@/assets/logo-no-text.png" />
        <h3>Ascendant HQ</h3>
      </div>
    </header>
    <div v-if="loggingIn">
      <h2>Log In</h2>
      <p>
        Please enter your email and password below. If you do not have an
        account and think you need one, talk to Kenny.
      </p>
      <form @submit.prevent="tryLogin">
        <TextInputField title="Username" v-model:value="username" />
        <TextInputField
          title="Password"
          v-model:value="password"
          inputType="password"
        />
        <div class="error">
          {{ errorMessage }}
        </div>
        <p>
          As you use the functionality provided here, be sure to remember the
          importance of confidentiality, security, respect and regulatory
          compliance!
        </p>
        <div class="login-container">
          <ButtonAscendant :disabled="false">Log in</ButtonAscendant>
        </div>
      </form>
      <p>
        <span>Need an Account? Contact your manager.</span>
      </p>
    </div>
    <div v-else-if="settingPassword">
      <div>
        <p>
          It looks like this is your first time here. Welcome! And we need to
          change your password. Enter a new one below.
        </p>
        <form>
          <TextInputField
            title="New Password"
            v-model:value="newPassword"
            inputType="password"
          />
          <TextInputField
            title="Confirm Password"
            v-model:value="passwordConfirm"
            inputType="password"
          />
        </form>
        <div v-if="!isValid" class="error">{{ passwordValidationMessage }}</div>
        <div v-if="errorMessage" class="error">
          {{ errorMessage }}
        </div>
        <div class="login-container">
          <ButtonAscendant :disabled="!isValid" :onClick="trySetPassword"
            >Set Password</ButtonAscendant
          >
        </div>
      </div>
    </div>
    <div v-else-if="doingMfa">
      <p>Enter your TOTP MFA code:</p>
      <TextInputField title="TOTP code" v-model:value="mfaCode" />
      <div class="login-container">
        <ButtonAscendant :disabled="false" :onClick="confirmMfa"
          >Log in</ButtonAscendant
        >
      </div>
    </div>
    <div v-else>
      <p>
        Use of this site requires that you set up TOTP 2FA. Below you will find
        your setup code and an input for the confirmation code that your device
        will generate when you setup. Insert the confirmation code and hit
        confirm.
      </p>
      <div class="qr-container">
        <vue-qrcode :value="totpSetupUrl" :options="{ width: 256 }" />
      </div>
      <p>If you can't use the QR code above, your TOTP setup code: {{ mfaSetupCode }}</p>
      <p>Use your prefered TOTP app to scan or type the code. Then, insert the code for the new account here to continue.</p>
      <TextInputField
        title="TOTP confirmation code"
        v-model:value="totpConfirmation"
      />
      <div class="login-container">
        <ButtonAscendant :disabled="false" :onClick="trySetMfa"
          >Log in</ButtonAscendant
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref } from "vue";
import authInterface from "@/authentication/AuthenticationInterface";
import ButtonAscendant from "@/views/components/forms/ButtonAscendant.vue";
import TextInputField from "@/views/components/forms/TextInputField.vue";
import { useRouter } from "vue-router";
import VueQrcode from "@chenfengyuan/vue-qrcode";

export default defineComponent({
  components: {
    ButtonAscendant,
    TextInputField,
    VueQrcode,
  },
  setup() {
    const router = useRouter();

    const username = ref("");
    const password = ref("");
    const errorMessage = ref("");
    const loggingIn = ref(true);
    const settingPassword = ref(false);
    const settingMfa = ref(false);
    const doingMfa = ref(false);
    const resultingUser = reactive({} as any);
    const mfaSetupCode = ref("");
    const totpConfirmation = ref("");
    const mfaCode = ref("");

    const totpSetupUrl = computed(() => `otpauth://totp/AscendantHQ:${username.value}?secret=${mfaSetupCode.value}&issuer=AscendantHQ`)
    const newPassword = ref("");
    const passwordConfirm = ref("");

    const passwordValidationMessage = computed(() => {
      if (newPassword.value.length <= 25)
        return "Your password must be at least 25 characters long and include numbers, letters of both cases and at least one symbol.";
      if (newPassword.value !== passwordConfirm.value)
        return "Your passwords do not match.";
      return "";
    });

    const isValid = computed(
      () => passwordValidationMessage.value.length === 0
    );

    const tryLogin = async () => {
      const result = await authInterface.login(username.value, password.value);

      if (result === null) {
        // Success!
        router.replace("/");
      } else if (result instanceof String || typeof result == "string") {
        errorMessage.value = result as string;
      } else {
        loggingIn.value = false;
        resultingUser.value = result;

        if ((result as any).challengeName === "MFA_SETUP") {
          mfaSetupCode.value = await authInterface.startTotp(
            resultingUser.value
          );
          settingMfa.value = true;
        } else if ((result as any).challengeName === "SOFTWARE_TOKEN_MFA") {
          doingMfa.value = true;
        } else {
          settingPassword.value = true;
        }
      }
    };

    const trySetPassword = async () => {
      try {
        await authInterface.finishRegistration(
          resultingUser.value,
          newPassword.value
        );
        settingPassword.value = false;
        await tryLogin();
      } catch (e) {
        errorMessage.value = e.toString();
      }
    };

    const trySetMfa = async () => {
      await authInterface.finishTotp(
        resultingUser.value,
        totpConfirmation.value
      );
    };

    const confirmMfa = async () => {
      await authInterface.confirmMfa(resultingUser.value, mfaCode.value);
    };

    const logout = async () => {
      await authInterface.logout();
    };

    return {
      username,
      password,
      newPassword,
      passwordConfirm,
      trySetPassword,
      errorMessage,
      tryLogin,
      loggingIn,
      isValid,
      logout,
      resultingUser,
      trySetMfa,
      settingMfa,
      mfaSetupCode,
      totpConfirmation,
      doingMfa,
      mfaCode,
      confirmMfa,
      settingPassword,
      passwordValidationMessage,
      totpSetupUrl
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.header {
  background-color: black;
  color: white;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0;
  height: 3.5rem;
}

.brand-container {
  margin: 0px 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  img {
    height: 2.5rem;
  }

  h3 {
    margin: 0;
  }
}

h2 {
  text-align: center;
}

p {
  text-align: center;
  font-size: 14px;
}

.clickableText {
  display: inline-block;
  text-decoration: underline;
  color: $ascendant-dark;
}

.clickableText:hover {
  cursor: pointer;
  color: $ascendant-mediumDark;
}

.error {
  color: $ascendant-primary;
  height: 18px;
  text-align: center;
  font-size: 14px;
}

.login-container {
  display: flex;
  justify-content: center;
  margin: 4vh 0;
}

.qr-container {
  display: flex;
  justify-content: center;
}
</style>

declare interface Date {
  IsSameDay(otherDate: Date): boolean;
  DateOnly(): Date;
  AddHours(num: number): Date;
  AddDays(num: number): Date;
  AddMonths(num: number): Date;
  AddYears(num: number): Date;
  GetDaysInMonth(): number;
  monthAbrvString(): string;
  shortFormat(): string;
}

declare interface DateConstructor {
  GetDaysInMonth(year: number, month: number): number;
  IsLeapYear(year: number): boolean;
}

Date.GetDaysInMonth = (year, month) => {
  return [
    31,
    Date.IsLeapYear(year) ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ][month];
};

Date.IsLeapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

Date.prototype.IsSameDay = function(otherDate: Date) {
  return this.DateOnly().getTime() === otherDate.DateOnly().getTime();
};

Date.prototype.DateOnly = function() {
  const newDate = new Date(this);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

Date.prototype.GetDaysInMonth = function() {
  return Date.GetDaysInMonth(this.getFullYear(), this.getMonth());
};

Date.prototype.AddHours = function(num: number) {
  return new Date(this.getTime() + 1000 * 60 * 60 * num);
};

Date.prototype.AddDays = function(num: number) {
  return new Date(this.getTime() + 1000 * 60 * 60 * 24 * num);
};

Date.prototype.AddMonths = function(value) {
  const n = this.getDate();
  this.setDate(1);
  this.setMonth(this.getMonth() + value);
  this.setDate(Math.min(n, this.GetDaysInMonth()));
  return this;
};

Date.prototype.AddYears = function(value) {
  const newDate = new Date(this.getTime());
  newDate.setFullYear(newDate.getFullYear() + value);
  return newDate;
};

Date.prototype.monthAbrvString = function() {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[this.getMonth()];
};

Date.prototype.shortFormat = function() {
  return `${this.monthAbrvString()} ${this.getDate()}, ${this.getFullYear()}`;
};

import ApiInterface from "@/api/ApiInterface";
import { createStore } from "vuex";
import { Practice } from "./Practice";
import { Provider } from "./Provider";
import { Announcement } from "./Announcement";

export default createStore({
  state: {
    loadingPractices: false,
    loadingProviders: false,
    loadingAnnouncements: false,
    practices: [] as Practice[],
    providers: [] as Provider[],
    announcements: [] as Announcement[],
  },
  mutations: {
    setPractices(state, practices: Practice[]) {
      state.practices = practices;
    },
    setProviders(state, providers: Provider[]) {
      state.providers = providers;
    },
    setAnnouncements(state, announcements: Announcement[]) {
      state.announcements = announcements;
    },
    setAnnouncement(state, announcement: Announcement) {
      state.announcements = [...state.announcements.filter(a => a.id != announcement.id), announcement];
    }
  },
  actions: {
    async getPractices(state) {
      if (state.state.loadingPractices) {
        return;
      }

      state.state.loadingPractices = true;
      state.commit("setPractices", await ApiInterface.getPractices());
      state.state.loadingPractices = false;
    },
    async getProviders(state) {
      if (state.state.loadingProviders) {
        return;
      }

      state.state.loadingProviders = true;
      state.commit("setProviders", await ApiInterface.getProviders());
      state.state.loadingProviders = false;
    },
    async getAnnouncements(state) {
      if (state.state.loadingAnnouncements) {
        return;
      }

      state.state.loadingAnnouncements = true;
      state.commit("setAnnouncements", await ApiInterface.getAnnouncements());
      state.state.loadingAnnouncements = false;
    },
    async getAnnouncement(state, announcementId) {
      if (state.state.loadingAnnouncements) {
        return;
      }

      state.state.loadingAnnouncements = true;
      state.commit("setAnnouncement", await ApiInterface.getAnnouncement(announcementId));
      state.state.loadingAnnouncements = false;
    }
    
  },
  modules: {},
});

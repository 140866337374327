import Amplify, { Auth, Hub } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import router from "@/router";

type authEventHandler = (email?: string) => void;

class AuthenticationInterface {
  isConfigured = false;
  configuredResolvers: Function[] = [];

  awaitConfigured(): Promise<void> {
    if (!this.isConfigured) {
      let resolver: () => void = () => {
        console.log("nothing");
      };
      const retVal = new Promise<void>((resolve) => {
        resolver = resolve;
      });

      this.configuredResolvers.push(resolver);

      return retVal;
    } else {
      return Promise.resolve();
    }
  }

  setConfigured() {
    console.log("Setting configured");
    this.isConfigured = true;
    this.configuredResolvers.forEach((r) => {
      r();
    });
  }

  listenAuthStatus(
    onLogin: authEventHandler,
    onLogout: authEventHandler
  ): () => void {
    Auth.currentAuthenticatedUser().then((user) => {
      if (user !== undefined) {
        const email = user.attributes["email"];
        onLogin(email);
      } else {
        onLogout();
      }
    });

    return Hub.listen("auth", (data) => {
      console.log(data);
      const { payload } = data;

      if (payload.event == "signIn") {
        onLogin();
      } else if (payload.event == "signOut") {
        onLogout();
      }
    });
  }

  async login(
    email: string,
    password: string
  ): Promise<string | CognitoUser | null> {
    email = email.trim().toLowerCase();
    let user;
    try {
      user = await Auth.signIn(email, password);

      if (
        user.challengeName === "NEW_PASSWORD_REQUIRED" ||
        user.challengeName == "MFA_SETUP" ||
        user.challengeName == "SOFTWARE_TOKEN_MFA"
      ) {
        return user as CognitoUser;
      }

      console.log("User-----");
      console.log(user);
      return null;
    } catch (e) {
      if (e.message === "Pending sign-in attempt already in progress") {
        return user;
      }

      console.log("Error-----");
      console.log(e);

      switch (e.code) {
        case "UserNotFoundException":
        case "NotAuthorizedException":
          return "Incorrect username or password.";
        case "UserNotConfirmedException":
          return e.code;
      }

      return "Error signing in. Please try again later.";
    }
  }

  async finishRegistration(user: CognitoUser, newPassword: string) {
    await Auth.completeNewPassword(
      user, // the Cognito User Object
      newPassword
    );
  }

  async startTotp(user: CognitoUser) {
    return await Auth.setupTOTP(user);
  }

  async finishTotp(user: CognitoUser, answer: string) {
    await Auth.verifyTotpToken(user, answer);
    await Auth.setPreferredMFA(user, "TOTP");
    router.replace("/");
  }

  async confirmMfa(user: CognitoUser, code: string) {
    await Auth.confirmSignIn(user, code, "SOFTWARE_TOKEN_MFA");
    router.replace("/");
  }

  async logout(): Promise<string | null> {
    try {
      console.log("Loggin out");
      await Auth.signOut();
      return null;
    } catch (err) {
      return "error!";
    }
  }

  async isLoggedIn(): Promise<boolean> {
    try {
      const username = await this.userName();
      const res = username !== undefined && username.length > 0;
      return res;
    } catch {
      return false;
    }
  }

  async currentUser(): Promise<CognitoUser> {
    return await Auth.currentAuthenticatedUser();
  }

  async userName(): Promise<string> {
    return ((await this.currentUser()) as CognitoUser).getUsername();
  }

  async sessionToken(): Promise<string> {
    try {
      return (await Auth.currentSession()).getIdToken().getJwtToken();
    } catch (e) {
      console.log("Failed to fetch token");
      console.log(e);
      return "";
    }
  }

  async register(email: string, password: string): Promise<string | null> {
    email = email.trim().toLowerCase();

    try {
      const user = await Auth.signUp(email, password);
      return null;
    } catch (e) {
      console.log(e);

      switch (e.code) {
        case "UsernameExistsException":
          return "A user with that email already exists. Try signing in?";
        case "InvalidParameterException":
          return "Looks like your email may not be valid, please check it again.";
      }

      return "Error signing in. Please try again later.";
    }
  }
}

const global = new AuthenticationInterface();
export default global;

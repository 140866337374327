<template>
  <div class="home">
    <p>Welcome. Click a navigation link above to get started.</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  components: {
  }
});
</script>


import { defineComponent, onMounted } from "vue";
import Header from "@/views/components/layout/Header.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "AuthenticatedLayout",
  components: {
    Header,
  },
  setup() {
    const store = useStore();

    const fetchThings = async () => {
      store.dispatch("getPractices");
      store.dispatch("getProviders");
      store.dispatch("getAnnouncements");
    };

    onMounted(fetchThings);
  },
  methods: {

  },
});

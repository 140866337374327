
import { defineComponent, PropType } from "vue";


export default defineComponent({
  props: {
    title: String,
    value: String,
    inputType: {
      type: String as PropType<'text' | 'number' | 'password'>,
      default: 'text'
    }
  },
})


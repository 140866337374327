
import authInterface from "@/authentication/AuthenticationInterface";

export default {
  methods: {
    async logout() {
      await authInterface.logout();
    },
  },
};

<template>
  <header class="header">
    <div class="brand-container">
      <h3>
        <router-link :to="{ name: 'Home' }" class="brand-link"
          >Ascendant HQ</router-link
        >
      </h3>
    </div>
    <div class="left-links">
      <li><router-link :to="{ name: 'Practices' }">Practices</router-link></li>
      <li><router-link :to="{ name: 'Providers' }">Providers</router-link></li>
      <li>
        <router-link :to="{ name: 'WeeklyReport' }">Weekly Report</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'ListAnnouncements' }"
          >Announcements</router-link
        >
      </li>
    </div>
    <div class="right-links">
      <li><a class="" @click.prevent="logout">Log out</a></li>
    </div>
  </header>
</template>

<script lang="ts">
import authInterface from "@/authentication/AuthenticationInterface";

export default {
  methods: {
    async logout() {
      await authInterface.logout();
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-color: black;
  color: white;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 5rem;
}

.brand-container {
  margin: 0px 20px;
}

.brand-link {
  color: white;
  text-decoration: none;
}

.left-links {
  flex: 1;
}

.left-links li, .right-links li {
  display: inline-block;
  margin: 10px;
}

.left-links li a, .right-links li a {
  color: white;
}
</style>
